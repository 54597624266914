import {
  continueExistingLogRocketSession,
  identifyUserWithLogRocket,
  initLogRocketSession,
} from '@zola-helpers/client/dist/es/util/logrocket/logRocketUtils';

import Logger from './logger';

const checkForLogRocket = () => {
  if (window && !window.LogRocket) {
    Logger.warn(
      'LogRocket is not loaded. Use loadLogRocket before continuing or initializing a session.'
    );
  }
};

export const handleLogRocketErrors = (error: unknown) => {
  Logger.error(`LogRocket failed to load: ${error}`);
};

export const loadLogRocket = async () => {
  if (typeof window !== 'undefined' && !window.LogRocket) {
    const { default: LogRocket } = await import('logrocket' /* webpackChunkName: "logrocket" */);
    window.LogRocket = LogRocket;
  }
};

export const continueLogRocket = () => {
  checkForLogRocket();
  continueExistingLogRocketSession();
};

export const initLogRocket = (
  condition: boolean,
  eventName?: string,
  projectName?: 'marketplace' | 'production'
) => {
  const isVendorPath = window.location.pathname.startsWith('/inspire/vendors');
  const defaultEventName = isVendorPath ? 'MARKETPLACE_VENDORS' : 'MARKETPLACE';
  const initEventName = eventName || defaultEventName;
  checkForLogRocket();
  initLogRocketSession(condition, {
    initEventName,
    projectName,
  });
};

export const identifyVendorUserWithLogRocket = (
  userUuid: string,
  name: string,
  email: string,
  storefrontUuid: string
) => {
  identifyUserWithLogRocket({
    uid: userUuid,
    name,
    email,
    traits: { storefrontUuid, type: 'VENDOR' },
  });
};
