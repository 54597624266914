import { continueExistingSessionReplay } from '@zola-helpers/client/dist/es/util/mixpanel/sessionReplay';
import { enhancedContext } from '@zola/tracking-contracts/src/plugins/enhancedContext';
import { enhancedTraits } from '@zola/tracking-contracts/src/plugins/enhancedTraits';
import { mixpanelContext } from '@zola/tracking-contracts/src/plugins/mixpanelContext';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';

import { AppProps } from 'next/app';

import { ModalProvider } from '~/contexts/ModalContext';
import { continueLogRocket, handleLogRocketErrors, loadLogRocket } from '~/util/logrocketUtils';

import '~/stylesheets/core.less';

type CustomAppProps = AppProps & {
  err?: unknown;
};

// Initialize Segment before any other rendering
if (typeof window !== 'undefined' && window.analytics) {
  continueExistingSessionReplay();
  window.analytics.register(enhancedContext, enhancedTraits(), mixpanelContext());
  window.analytics.page();
}

const CustomApp = ({ Component, pageProps, err }: CustomAppProps) => {
  useEffectOnce(() => {
    loadLogRocket().then(continueLogRocket).catch(handleLogRocketErrors);
  });

  return (
    <ModalProvider>
      <Component {...pageProps} err={err} />
    </ModalProvider>
  );
};

export default CustomApp;
