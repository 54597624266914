/* eslint-disable no-console, no-restricted-syntax */
interface LogLevel {
  priority: number;
  name: string;
}

interface CaptureOptions {
  tags?: {
    [tagName: string]: string | number | boolean;
  };
  extra?: {
    [tagName: string]: string | number | boolean;
  };
}

export interface TaggedError extends Error, CaptureOptions {}

const assignLogLevel = (priority: number, name: string): LogLevel => ({
  priority,
  name,
});

const LoggerConstants: { [level: string]: LogLevel } = {
  TRACE: assignLogLevel(1, 'TRACE'),
  DEBUG: assignLogLevel(2, 'DEBUG'),
  INFO: assignLogLevel(3, 'INFO'),
  WARN: assignLogLevel(5, 'WARN'),
  ERROR: assignLogLevel(8, 'ERROR'),
};

const handleOutput = (level: LogLevel, args: unknown[]) => {
  const [message, ...context] = args;

  if (!console) {
    return;
  }

  // TODO: Remove me, Logger.error shouldn't be ignored in tests, but now too many tests fail if we remove this
  if (process.env.NODE_ENV === 'test') {
    return;
  }

  if (level === LoggerConstants.ERROR && process.env.NODE_ENV === 'production') {
    const contextObject = (context && context[0]) || {};
    const { tags, extra } = contextObject as CaptureOptions;

    // Both captureException and captureMessage support sending additional data with the event,
    // and we'll include `tags` or `extra` objects if the context object contains them
    // See https://docs.logrocket.com/docs/error-reporting
    if (typeof window !== 'undefined' && window.LogRocket) {
      if (contextObject instanceof Error) {
        window.LogRocket.captureException(contextObject, { tags, extra });
      } else {
        window.LogRocket.captureMessage(message as string, { tags, extra });
      }
    }
    return;
  }

  if (level === LoggerConstants.WARN && console.warn) {
    console.warn(message, ...context);
  } else if (level === LoggerConstants.ERROR && console.error) {
    console.error(message, ...context);
  } else if (level === LoggerConstants.INFO && console.info) {
    console.info(message, ...context);
  } else if (level === LoggerConstants.DEBUG && console.debug) {
    console.debug(message, ...context);
  } else if (level === LoggerConstants.TRACE && console.trace) {
    console.trace(message, ...context);
  } else {
    console.debug(message, ...context);
  }
};

const trace = (...args: unknown[]): void => handleOutput(LoggerConstants.TRACE, args);
const debug = (...args: unknown[]): void => handleOutput(LoggerConstants.DEBUG, args);
const info = (...args: unknown[]): void => handleOutput(LoggerConstants.INFO, args);
const warn = (...args: unknown[]): void => handleOutput(LoggerConstants.WARN, args);
const error = (...args: unknown[]): void => handleOutput(LoggerConstants.ERROR, args);

const Logger = {
  debug,
  info,
  warn,
  error,
  trace,
};

export const StandardLogFn = (err: Error): void => {
  Logger.error(err.message || err);
};

export default Logger;
